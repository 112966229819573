import React, { SVGProps, useId } from 'react';

type Props = SVGProps<SVGSVGElement>;
export const GlutenFreeIcon = (props: Props) => {
    return (
        <svg
            width="24px"
            height="24px"
            version="1.1"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g>
                <path
                    className="fill-current"
                    d="m50 2.5c-26.199 0-47.5 21.301-47.5 47.5s21.301 47.5 47.5 47.5 47.5-21.301 47.5-47.5-21.301-47.5-47.5-47.5zm30.801 72.898-17.102-17.098c-0.30078 0.39844-0.80078 0.80078-1.1992 1.1992 0.30078 1.1992 0.30078 2.5-0.19922 3.6016-0.30078 0.80078-0.80078 1.8984-1.5 3l14.602 14.699c-7.1992 5.8984-16.199 9.1992-25.398 9.1992-22.004-0.10156-39.902-18-39.902-40 0-9.1992 3.3008-18.199 9.1992-25.398l16.602 16.602c0.60156-1.6992 1.3008-3.1016 1.8984-4.1016 0.60156-1 1.5-1.6992 2.5-2.1992l-15.699-15.602c7.1992-6 16.199-9.1992 25.398-9.1992 22 0 39.898 17.898 39.898 39.898 0 9.1992-3.1992 18.199-9.0977 25.398z"
                />
                <path
                    className="fill-current"
                    d="m40.102 68.102c-0.39844-0.19922-0.89844 0-1.1016 0.39844l-4.3008 7.8984c-0.19922 0.39844-0.10156 1 0.39844 1.1992l2.3008 1.1016c0.39844 0.19922 1 0 1.1992-0.39844l3.6992-8.1992c0.19922-0.39844 0-0.89844-0.39844-1.1016z"
                />
                <path
                    className="fill-current"
                    d="m57.102 36.898c1.6016-0.60156 5.3008-2.1992 6.8984-5.5 1.6016-3.3008 0.69922-7.1992 0.19922-8.8008-0.10156-0.39844-0.60156-0.69922-1-0.5-1.6016 0.60156-5.3008 2.1992-6.8984 5.5-1.6016 3.3008-0.69922 7.1992-0.19922 8.8008 0.19922 0.40234 0.59766 0.70312 1 0.5z"
                />
                <path
                    className="fill-current"
                    d="m68.199 38c-1.6016-0.5-5.5-1.6016-8.8008-0.10156-3.3008 1.5-5.1016 5.1992-5.6992 6.6992-0.19922 0.39844 0 0.89844 0.5 1 1.6016 0.5 5.5 1.6016 8.8008 0.10156s5.1016-5.1992 5.6992-6.6992c0.19922-0.39844-0.097657-0.89844-0.5-1z"
                />
                <path
                    className="fill-current"
                    d="m51 44.102c0.39844 0.19922 0.89844 0.10156 1.1016-0.19922 0.89844-1.5 2.6992-5.1016 1.8984-8.6016-0.80078-3.6016-4-6-5.3984-7-0.39844-0.19922-0.89844-0.10156-1.1016 0.19922-0.89844 1.5-2.6992 5.1016-1.8984 8.6016 0.79688 3.5977 4 6.0977 5.3984 7z"
                />
                <path
                    className="fill-current"
                    d="m62.699 49.102c-1.6016-0.5-5.5-1.6016-8.8008-0.10156-3.3008 1.5-5.1016 5.1992-5.6992 6.6992-0.19922 0.39844 0 0.89844 0.5 1 1.6016 0.5 5.5 1.6016 8.8008 0.10156s5.1016-5.1992 5.6992-6.6992c0.10156-0.40234-0.097657-0.80078-0.5-1z"
                />
                <path
                    className="fill-current"
                    d="m45.5 55.301c0.39844 0.19922 0.89844 0.10156 1.1016-0.19922 0.89844-1.5 2.6992-5.1016 1.8984-8.6016-0.80078-3.6016-4-6-5.3984-7-0.39844-0.19922-0.89844-0.10156-1.1016 0.19922-0.89844 1.5-2.6992 5.1016-1.8984 8.6016 0.79688 3.5977 4 6 5.3984 7z"
                />
                <path
                    className="fill-current"
                    d="m57.199 60.301c-1.6016-0.5-5.5-1.6016-8.8008-0.10156-3.3008 1.5-5.1016 5.1992-5.6992 6.6992-0.19922 0.39844 0 0.89844 0.5 1 1.6016 0.5 5.5 1.6016 8.8008 0.10156s5.1016-5.1992 5.6992-6.6992c0.10156-0.40234-0.097657-0.90234-0.5-1z"
                />
                <path
                    className="fill-current"
                    d="m40 66.398c0.39844 0.19922 0.89844 0.10156 1.1016-0.19922 0.89844-1.5 2.6992-5.1016 1.8984-8.6016-0.80078-3.6016-4-6-5.3984-7-0.39844-0.19922-0.89844-0.10156-1.1016 0.19922-0.89844 1.5-2.6992 5.1016-1.8984 8.6016 0.79688 3.6016 4 6.1016 5.3984 7z"
                />
            </g>
        </svg>
    );
};

export const CarrotIcon = (props: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 100 100" {...props}>
        <path d="M64.2,22.6a.75.75,0,0,0-1-.5" />
        <g>
            <path d="M78.5,15.066a5.929,5.929,0,0,0-4.786.819h0a3.824,3.824,0,0,0-1.62,2.977,3.549,3.549,0,0,0,.036.563v0a2.957,2.957,0,0,1-.424,2.032c-.036.053-.068.106-.1.159a.626.626,0,0,1-1.139-.256,8.375,8.375,0,0,0-1.326-3.552,4.431,4.431,0,0,0-7.991,2.474,9.836,9.836,0,0,0,2.024,5.814,5.35,5.35,0,0,1,.4,5.366c-.191.389-.406.8-.642,1.217h0a.844.844,0,0,0,.2,1.072,13.937,13.937,0,0,1,2.63,2.68.837.837,0,0,0,1.154.183c.483-.348.978-.672,1.473-.96a5.35,5.35,0,0,1,5.313-.053,11.593,11.593,0,0,0,4.342,1.49c5.4.524,6.538-7.434,1.2-8.43a7.507,7.507,0,0,0-.765-.106H78.46a.628.628,0,0,1-.483-.936l.017-.03a3.274,3.274,0,0,1,2.368-1.5,5.718,5.718,0,0,0,2.445-.845,2.9,2.9,0,0,0,1.228-2.053h0a10.867,10.867,0,0,0,.083-1.108,7.016,7.016,0,0,0-5.629-7.018Z" />
            <path d="M63.887,39.091a13.26,13.26,0,0,0-13.7-5.682c-7.982,1.62-14.028,9.278-14.028,9.278A94.66,94.66,0,0,0,15.938,82.181a2.388,2.388,0,0,0,2.7,2.892c19.494-3.04,39.985-23.907,39.985-23.907,7.163-7.316,8.135-13.384,7.113-17.79h0a13.08,13.08,0,0,0-1.85-4.285ZM33,75.839a1.535,1.535,0,0,1-2.162-.1c-1-1.1-2.633-2.716-2.651-2.731v0a1.53,1.53,0,0,1,2.156-2.171c.071.068,1.7,1.685,2.754,2.839l0,0A1.526,1.526,0,0,1,33,75.839Zm7.787-12.824v0a1.509,1.509,0,0,1-1.116.351,1.526,1.526,0,0,1-1.037-.542,39.121,39.121,0,0,0-4.954-4.554v0a1.522,1.522,0,0,1-.615-1.019,1.528,1.528,0,0,1,2.48-1.4,41.328,41.328,0,0,1,5.437,5.019,1.521,1.521,0,0,1-.2,2.15Zm12.33-2.987h0a1.528,1.528,0,0,1-2.136-.344A35.015,35.015,0,0,0,46.9,55.366v0a1.53,1.53,0,0,1,2.035-2.277A37.666,37.666,0,0,1,53.457,57.9h0a1.531,1.531,0,0,1-.344,2.129Zm.936-13.121a1.532,1.532,0,0,1-2.138-.315A32.749,32.749,0,0,0,47.1,42.1v0a1.529,1.529,0,0,1,1.868-2.415,36.409,36.409,0,0,1,5.39,5.086v0a1.527,1.527,0,0,1-.31,2.144Z" />
        </g>
    </svg>
);

export const VegetarianLeaf = (props: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 100 100" {...props}>
        <path d="M64.2,22.6a.75.75,0,0,0-1-.5" className="fill-current" />
        <g>
            <path
                className="fill-current"
                d="M50,2.577A47.475,47.475,0,1,0,83.518,16.482,47.436,47.436,0,0,0,50,2.577Zm0,87.4A40.047,40.047,0,1,1,78.249,78.25,39.99,39.99,0,0,1,50,89.979Z"
            />
            <path
                className="fill-current"
                d="M67.31,23.924a2.64,2.64,0,0,0-3.15,2.236,29.123,29.123,0,0,1-2.848,8.216.724.724,0,0,1-.127.185c-1.246-.228-2.438-.532-3.644-.674l0,0a17.588,17.588,0,0,0-11.9,2.784A18.6,18.6,0,0,0,36.79,49.785c-.416,2.2-.621,4.408-.976,6.6a10.19,10.19,0,0,1-1.937,4.991,8.078,8.078,0,0,1-5.57,2.968c-.842.119-1.7.141-2.548.274l0,0a5.089,5.089,0,0,0-1.072.4,3.191,3.191,0,0,0,.612,1.05,58.9,58.9,0,0,0,4.766,3.62,22.547,22.547,0,0,0,11.654,3.606,26.38,26.38,0,0,0,11.154-2.136,38.274,38.274,0,0,0,13.8-9.523A17.691,17.691,0,0,0,71.4,49.3a15.747,15.747,0,0,0-2.816-9.1c-.04-.129-.168-.222-.259-.336s0,0-.143-.075C61.557,50.309,53.1,58.812,41.361,63.664l.205-.184.209-.142A95.649,95.649,0,0,0,61.635,43.9a42.479,42.479,0,0,0,7-13.489,31.7,31.7,0,0,0,.764-3.461,2.6,2.6,0,0,0-2.089-3.021Z"
            />
        </g>
    </svg>
);

export const PrefareLogo = ({ height = '40px', ...props }: Omit<Props, 'height'> & { height?: string | null }) => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 278.53 61.54"
            {...props}
            style={{ height: height === null ? undefined : height }}
        >
            <defs>
                <style>
                    {
                        '.cls-1{ fill:#ff9e16; }.cls-2{ fill:#4daa95;stroke:#4daa95;stroke-width:0.26px; }.cls-2,.cls-5{ stroke - miterlimit:10; }.cls-3,.cls-5{ fill:none; }.cls-4{ fill:#7d6b67; }.cls-5{ stroke:#7d6b67;stroke-width:0.26px; }'
                    }
                </style>
            </defs>
            <title>Untitled-2</title>
            <path
                className="cls-1"
                d="M210.83,378.57c-2.65,4.63-.89,7.7,1.07,9.49a19.55,19.55,0,0,0,7.64,3.79c2.87,0.84,4.89,1.52,6.09,1.95,0.86,0.3,1.43.52,1.75,0.65a1.78,1.78,0,0,0,.53,1.79l8.91,8.1a1.71,1.71,0,1,0,2.29-2.52l-8.91-8.1a1.83,1.83,0,0,0-1.73-.42v0c-0.15-.3-0.42-0.85-0.82-1.7-0.55-1.18-1.43-3.15-2.52-5.88a19.62,19.62,0,0,0-4.5-7.24l0,0h0c-1.94-1.76-5.16-3.21-9.52-.13l-0.07.06Zm9.09,11.94a18.18,18.18,0,0,1-7.08-3.48c-2.13-2-2.51-4.17-1.19-7l13.21,12C223.3,391.53,221.64,391,219.92,390.51Zm-0.21-11a18.16,18.16,0,0,1,4.13,6.72q1,2.51,2,4.76l-13.21-12C215.3,377.37,217.55,377.54,219.71,379.49Z"
                transform="translate(-166.39 -365.29)"
            />
            <polygon
                className="cls-1"
                points="105.71 33.59 103.48 33.59 89.99 33.59 90.42 35.29 103.48 35.29 105.71 35.29 121.97 35.29 122.39 33.59 105.71 33.59"
            />
            <path
                className="cls-1"
                d="M272.29,385.69h0c-5.68.46-10.14,6.08-10.19,11.87h25.24a14.77,14.77,0,0,0-7.79-12.73V383.6a4.13,4.13,0,0,0-1.27-2.76c-1.22-1.22-3.13-1.84-5.68-1.84-6.87,0-7,4.54-7,4.6v1.23a14.76,14.76,0,0,0-7.79,12.73h2.49C260.38,391.47,266.2,385.7,272.29,385.69Zm-4.73-2.09s0.08-2.66,5-2.66,5,2.56,5,2.65V384a14.52,14.52,0,0,0-10,0V383.6Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-1"
                d="M171.14,382.43l2.09,0.38a0.81,0.81,0,0,1,.34.15,0.83,0.83,0,0,1,.34.8l-0.4,2.75,3.27-2.73a0.84,0.84,0,0,1,1.09,0l2.91,2.61-0.35-2.67a0.83,0.83,0,0,1,.14-0.58l0-.06a0.84,0.84,0,0,1,.59-0.3l2.63-.17a12.91,12.91,0,0,0-5.26-1.57,0.84,0.84,0,0,1-.74-0.6l-0.11-.38a32.35,32.35,0,0,0-1.23-3.77L175,377a10.43,10.43,0,0,1,1.27,3.18,0.84,0.84,0,0,1-.72,1A11,11,0,0,0,171.14,382.43Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-1"
                d="M185,383.74l-3.41.22,0.54,4.09a0.48,0.48,0,0,1-.79.42l-4-3.58L173,388.48a0.48,0.48,0,0,1-.78-0.44l0.6-4.14-2.28-.42a10.19,10.19,0,0,0-4.16,8.12c0,5.78,5,10.47,11.21,10.47s11.21-4.69,11.21-10.47A10.15,10.15,0,0,0,185,383.74Zm-13.88,14c-1.44-.22-4.75-6-0.6-10.88C169,390.09,170.12,396.87,171.1,397.72Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-2"
                d="M333.12,421.41h0.45v3.46a2,2,0,0,1-.62,1.3,2.06,2.06,0,0,1-.62.39,2,2,0,0,1-1.51,0,2,2,0,0,1-1.06-1.06,1.93,1.93,0,0,1-.16-0.77v-5.42a1.93,1.93,0,0,1,.16-0.77,2,2,0,0,1,1.06-1.06,2,2,0,0,1,1.54,0,2,2,0,0,1,1,1,1.93,1.93,0,0,1,.16.77h-0.45a1.42,1.42,0,0,0-.12-0.59,1.59,1.59,0,0,0-.81-0.81,1.42,1.42,0,0,0-.59-0.12,1.47,1.47,0,0,0-.6.12,1.57,1.57,0,0,0-.49.33,1.61,1.61,0,0,0-.33.48,1.43,1.43,0,0,0-.12.59v5.42a1.47,1.47,0,0,0,.12.6,1.55,1.55,0,0,0,.82.82,1.46,1.46,0,0,0,.6.12,1.42,1.42,0,0,0,.59-0.12,1.59,1.59,0,0,0,.48-0.33,1.57,1.57,0,0,0,.33-0.49,1.46,1.46,0,0,0,.12-0.6v-2.85h-1.86v-0.45h1.86Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-2"
                d="M337.88,426.7a1.93,1.93,0,0,1-.77-0.16,2,2,0,0,1-1.06-1.06,1.93,1.93,0,0,1-.16-0.77v-5.42a1.93,1.93,0,0,1,.16-0.77,2,2,0,0,1,1.06-1.06,1.93,1.93,0,0,1,.77-0.16,1.88,1.88,0,0,1,.77.16,2,2,0,0,1,.62.43,2,2,0,0,1,.43.63,1.93,1.93,0,0,1,.16.77v5.42a1.93,1.93,0,0,1-.16.77,2,2,0,0,1-1,1.06A1.88,1.88,0,0,1,337.88,426.7Zm0-8.94a1.47,1.47,0,0,0-.6.12,1.57,1.57,0,0,0-.49.33,1.61,1.61,0,0,0-.33.48,1.43,1.43,0,0,0-.12.59v5.42a1.47,1.47,0,0,0,.12.6,1.55,1.55,0,0,0,.82.82,1.46,1.46,0,0,0,.6.12,1.42,1.42,0,0,0,.59-0.12,1.6,1.6,0,0,0,.48-0.33,1.58,1.58,0,0,0,.33-0.49,1.47,1.47,0,0,0,.12-0.6v-5.42a1.42,1.42,0,0,0-.12-0.59,1.59,1.59,0,0,0-.81-0.81A1.42,1.42,0,0,0,337.88,417.76Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-2"
                d="M346.15,417.36v7.34a1.93,1.93,0,0,1-.16.77,2,2,0,0,1-1,1.06,1.88,1.88,0,0,1-.77.16,1.93,1.93,0,0,1-.77-0.16,2,2,0,0,1-1.06-1.06,1.93,1.93,0,0,1-.16-0.77v-7.34h0.45v7.34a1.47,1.47,0,0,0,.12.6,1.55,1.55,0,0,0,.82.82,1.47,1.47,0,0,0,.6.12,1.42,1.42,0,0,0,.59-0.12,1.6,1.6,0,0,0,.48-0.33,1.57,1.57,0,0,0,.33-0.49,1.46,1.46,0,0,0,.12-0.6v-7.34h0.45Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-2"
                d="M350.08,422.72H349v3.91h-0.45v-9.27h1.67a2,2,0,0,1,.77.15,1.91,1.91,0,0,1,.63.42,2,2,0,0,1,.42.62,1.89,1.89,0,0,1,.16.77v1.44a1.82,1.82,0,0,1-.12.67,2,2,0,0,1-.34.58,2,2,0,0,1-1.14.67l1.67,3.95h-0.48Zm-1.12-.45h1.22a1.5,1.5,0,0,0,1.07-.44,1.59,1.59,0,0,0,.33-0.48,1.42,1.42,0,0,0,.12-0.59v-1.44a1.39,1.39,0,0,0-.12-0.58,1.59,1.59,0,0,0-.81-0.81,1.43,1.43,0,0,0-.59-0.12H349v4.46Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-2"
                d="M360.06,417.36v9.27h-0.45v-8l-2.29,6.29-2.3-6.29v8h-0.45v-9.27H355l2.29,6.25,2.28-6.25h0.47Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-2"
                d="M362.88,417.81v3.51h2.59v0.45h-2.59v4.4H366v0.45h-3.61v-9.27H366v0.45h-3.15Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-2"
                d="M372.1,417.81h-1.85v8.82h-0.45v-8.82h-1.85v-0.45h4.15v0.45Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-2"
                d="M383,417.36v9.27h-0.45v-8l-2.29,6.29-2.3-6.29v8h-0.45v-9.27H378l2.29,6.25,2.28-6.25H383Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-2"
                d="M385.83,417.81v3.51h2.59v0.45h-2.59v4.4H389v0.45h-3.61v-9.27H389v0.45h-3.15Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-2"
                d="M395.45,426.63l-0.64-2.91h-2.66l-0.64,2.91H391l2.06-9.27h0.77l2.06,9.27h-0.47Zm-3.21-3.36h2.47l-1.23-5.54Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-2"
                d="M401.43,426.18v0.45H398v-9.27h0.45v8.82h2.93Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-2"
                d="M408.21,422h-1.06v4.59h-0.45v-9.27h0.45v4.22h1.06l2.19-4.22h0.51l-2.3,4.44,2.51,4.83h-0.51Z"
                transform="translate(-166.39 -365.29)"
            />
            <path className="cls-2" d="M413.18,426.63v-9.27h0.45v9.27h-0.45Z" transform="translate(-166.39 -365.29)" />
            <path
                className="cls-2"
                d="M419.83,417.81H418v8.82h-0.45v-8.82h-1.85v-0.45h4.15v0.45Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-2"
                d="M423.79,421.73l0.68,0.57a4.43,4.43,0,0,1,.6.65,3.1,3.1,0,0,1,.43.78,2.75,2.75,0,0,1,.16,1,1.93,1.93,0,0,1-.16.78,2,2,0,0,1-1,1.06,1.87,1.87,0,0,1-.77.16,1.93,1.93,0,0,1-.77-0.16,2,2,0,0,1-1.06-1.06,1.93,1.93,0,0,1-.16-0.77h0.45a1.47,1.47,0,0,0,.12.6,1.59,1.59,0,0,0,.33.49,1.57,1.57,0,0,0,.49.33,1.46,1.46,0,0,0,.6.12,1.42,1.42,0,0,0,.59-0.12,1.59,1.59,0,0,0,.48-0.33,1.57,1.57,0,0,0,.33-0.49,1.46,1.46,0,0,0,.12-0.6,2.29,2.29,0,0,0-.14-0.84,2.68,2.68,0,0,0-.38-0.68,4.06,4.06,0,0,0-.55-0.58l-0.64-.53-0.65-.54a4.29,4.29,0,0,1-.58-0.61,3,3,0,0,1-.41-0.73,2.48,2.48,0,0,1-.16-0.92,1.93,1.93,0,0,1,.16-0.77,2,2,0,0,1,1.06-1.06,2,2,0,0,1,1.54,0,2,2,0,0,1,1,1,1.93,1.93,0,0,1,.16.77h-0.45a1.42,1.42,0,0,0-.12-0.59,1.59,1.59,0,0,0-.81-0.81,1.42,1.42,0,0,0-.59-0.12,1.47,1.47,0,0,0-.6.12,1.57,1.57,0,0,0-.49.33,1.61,1.61,0,0,0-.33.48,1.43,1.43,0,0,0-.12.59,2,2,0,0,0,.14.78,2.57,2.57,0,0,0,.36.63,3.86,3.86,0,0,0,.52.54Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-3"
                d="M358.92,380.5a2.68,2.68,0,0,0-1.22-2.13,5.6,5.6,0,0,0-6.51,0,2.7,2.7,0,0,0-1.2,2.14v8.19h8.92V380.5Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-3"
                d="M316.32,380.5a2.68,2.68,0,0,0-1.22-2.13,5.6,5.6,0,0,0-6.51,0,2.7,2.7,0,0,0-1.2,2.14v22.55a2.66,2.66,0,0,0,1.19,2.17,5.75,5.75,0,0,0,6.53,0,2.64,2.64,0,0,0,1.21-2.16V380.5Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-3"
                d="M441.58,380.5a2.68,2.68,0,0,0-1.22-2.13,5.6,5.6,0,0,0-6.51,0,2.71,2.71,0,0,0-1.2,2.14v8.19h8.93V380.5Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-3"
                d="M400.34,393.74a2.69,2.69,0,0,0-1.22-2.14,5.61,5.61,0,0,0-6.51,0,2.71,2.71,0,0,0-1.2,2.14v9.31a2.66,2.66,0,0,0,1.19,2.16,5.75,5.75,0,0,0,6.53,0,2.64,2.64,0,0,0,1.21-2.16v-9.31Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-4"
                d="M339.32,376.21a7.92,7.92,0,0,0-2.47-1.4,8.91,8.91,0,0,0-3-.51,8.72,8.72,0,0,0-3.48.69,10.56,10.56,0,0,0-1,.48V374.3H326v34.95h3.41V380.5a2.7,2.7,0,0,1,1.19-2.14,5,5,0,0,1,3.26-1,5.48,5.48,0,0,1,1.82.3,4.71,4.71,0,0,1,1.43.79,3.47,3.47,0,0,1,.9,1.12,2.84,2.84,0,0,1,.31,1.29v1h3.34v-1a5.31,5.31,0,0,0-.66-2.56A7.46,7.46,0,0,0,339.32,376.21Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-4"
                d="M359.89,376.06a8.12,8.12,0,0,0-2.45-1.29,9.39,9.39,0,0,0-3-.47,9.59,9.59,0,0,0-3,.47,8.09,8.09,0,0,0-2.45,1.29,6.64,6.64,0,0,0-1.69,2,5,5,0,0,0-.68,2.48v22.55a5.44,5.44,0,0,0,2.39,4.53,9,9,0,0,0,5.48,1.67,8.83,8.83,0,0,0,5.45-1.68,5.46,5.46,0,0,0,2.36-4.52v-4.4h-3.34v4.4a2.64,2.64,0,0,1-1.21,2.16,5.75,5.75,0,0,1-6.52,0,2.66,2.66,0,0,1-1.19-2.17v-11.4h12.27V380.5a5,5,0,0,0-.68-2.48A6.67,6.67,0,0,0,359.89,376.06ZM350,380.5a2.7,2.7,0,0,1,1.2-2.14,5.6,5.6,0,0,1,6.51,0,2.68,2.68,0,0,1,1.22,2.13v8.19H350V380.5Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-4"
                d="M381.9,367.15a8.29,8.29,0,0,0-2.45-1.36A9.47,9.47,0,0,0,371,367a5.46,5.46,0,0,0-2.38,4.48v37.76H372V382.63h9.76v-3H372v-8.12a2.66,2.66,0,0,1,1.19-2.16,5.14,5.14,0,0,1,3.28-1,4.89,4.89,0,0,1,3.22,1.09,3,3,0,0,1,1.24,2.41v1h3.34v-1a5.43,5.43,0,0,0-.67-2.63A7,7,0,0,0,381.9,367.15Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-4"
                d="M401.31,376.06a8.08,8.08,0,0,0-2.45-1.29,9.39,9.39,0,0,0-3-.47,9.59,9.59,0,0,0-3,.47,8.08,8.08,0,0,0-2.45,1.29,6.61,6.61,0,0,0-1.69,2,5,5,0,0,0-.68,2.48v4.4h3.41v-4.4a2.7,2.7,0,0,1,1.2-2.14,5.6,5.6,0,0,1,6.51,0,2.68,2.68,0,0,1,1.22,2.13v8.19a9.61,9.61,0,0,0-1-.5,8.85,8.85,0,0,0-3.44-.66,9.65,9.65,0,0,0-3,.47,8.12,8.12,0,0,0-2.46,1.29,6.65,6.65,0,0,0-1.69,2,5,5,0,0,0-.68,2.48v9.31a5.47,5.47,0,0,0,2.39,4.49,8.86,8.86,0,0,0,5.49,1.71,9.27,9.27,0,0,0,3.42-.62,9,9,0,0,0,1-.49v1.11h3.34V380.5A5,5,0,0,0,403,378,6.69,6.69,0,0,0,401.31,376.06Zm-2.18,29.15a5.75,5.75,0,0,1-6.53,0,2.66,2.66,0,0,1-1.19-2.16v-9.31a2.71,2.71,0,0,1,1.2-2.14,5.61,5.61,0,0,1,6.51,0,2.69,2.69,0,0,1,1.22,2.14v9.31A2.64,2.64,0,0,1,399.13,405.21Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-4"
                d="M422.13,376.21a7.86,7.86,0,0,0-2.46-1.4,8.91,8.91,0,0,0-3-.51,8.71,8.71,0,0,0-3.48.69,10.58,10.58,0,0,0-1,.48V374.3h-3.41v34.95h3.41V380.5a2.7,2.7,0,0,1,1.2-2.14,5,5,0,0,1,3.27-1,5.49,5.49,0,0,1,1.82.3,4.71,4.71,0,0,1,1.43.79,3.45,3.45,0,0,1,.9,1.12,2.85,2.85,0,0,1,.31,1.29v1h3.35v-1a5.28,5.28,0,0,0-.67-2.57A7.34,7.34,0,0,0,422.13,376.21Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-4"
                d="M444.25,378a6.72,6.72,0,0,0-1.7-2,8.13,8.13,0,0,0-2.45-1.29,9.39,9.39,0,0,0-3-.47,9.59,9.59,0,0,0-3,.47,8.1,8.1,0,0,0-2.45,1.29,6.61,6.61,0,0,0-1.7,2,5,5,0,0,0-.68,2.48v22.55a5.44,5.44,0,0,0,2.4,4.53,9,9,0,0,0,5.48,1.67,8.85,8.85,0,0,0,5.45-1.68,5.47,5.47,0,0,0,2.36-4.52v-4.4h-3.34v4.4a2.64,2.64,0,0,1-1.21,2.16,5.75,5.75,0,0,1-6.53,0,2.67,2.67,0,0,1-1.19-2.17v-11.4h12.27V380.5A5,5,0,0,0,444.25,378Zm-11.6,2.48a2.71,2.71,0,0,1,1.2-2.14,5.6,5.6,0,0,1,6.51,0,2.68,2.68,0,0,1,1.22,2.13v8.19h-8.93V380.5Z"
                transform="translate(-166.39 -365.29)"
            />
            <path
                className="cls-4"
                d="M317.29,376.06a8.1,8.1,0,0,0-2.45-1.29,9.41,9.41,0,0,0-3-.47,8.7,8.7,0,0,0-3.48.69,10.6,10.6,0,0,0-1,.48V374.3H304v52.3h3.41V408.13a10.08,10.08,0,0,0,1,.47,9,9,0,0,0,3.46.65,8.84,8.84,0,0,0,5.45-1.68,5.47,5.47,0,0,0,2.36-4.52V380.5A5,5,0,0,0,319,378,6.68,6.68,0,0,0,317.29,376.06Zm-2.18,29.15a5.75,5.75,0,0,1-6.53,0,2.66,2.66,0,0,1-1.19-2.17V380.5a2.7,2.7,0,0,1,1.2-2.14,5.6,5.6,0,0,1,6.51,0,2.68,2.68,0,0,1,1.22,2.13v22.55A2.64,2.64,0,0,1,315.11,405.21Z"
                transform="translate(-166.39 -365.29)"
            />
            <line className="cls-5" x1="82.49" y1="8.67" x2="82.49" y2="41.9" />
            <line className="cls-5" x1="33.22" y1="8.67" x2="33.22" y2="41.9" />
        </svg>
    );
};

export const CheckCircle = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5002 4.50004C9.87274 4.50004 4.50016 9.87262 4.50016 16.5C4.50016 23.1275 9.87274 28.5 16.5002 28.5C23.1276 28.5 28.5002 23.1275 28.5002 16.5C28.5002 9.87262 23.1276 4.50004 16.5002 4.50004ZM1.8335 16.5C1.8335 8.39986 8.39999 1.83337 16.5002 1.83337C24.6003 1.83337 31.1668 8.39986 31.1668 16.5C31.1668 24.6002 24.6003 31.1667 16.5002 31.1667C8.39999 31.1667 1.8335 24.6002 1.8335 16.5Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.443 11.5572C23.9637 12.0778 23.9637 12.9221 23.443 13.4428L15.443 21.4428C14.9223 21.9635 14.0781 21.9635 13.5574 21.4428L10.224 18.1094C9.70332 17.5887 9.70332 16.7445 10.224 16.2238C10.7447 15.7031 11.5889 15.7031 12.1096 16.2238L14.5002 18.6143L21.5574 11.5572C22.0781 11.0365 22.9223 11.0365 23.443 11.5572Z"
                fill="currentColor"
            />
        </svg>
    );
};

export const QuoteIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="40" height="33" viewBox="0 0 40 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.8981 15.4865L16.9518 0.837891H7.83099L2.48749 14.1045C0.368518 19.1716 0 20.9221 0 23.3174C0 28.5688 3.7773 32.1619 8.93655 32.1619C14.0037 32.1619 17.781 28.6609 17.781 23.3174C17.781 19.5401 15.9384 16.7763 12.8981 15.4865ZM34.6406 15.4865L38.6943 0.837891H29.5735L24.23 14.1045C22.111 19.1716 21.7425 20.9221 21.7425 23.3174C21.7425 28.5688 25.5198 32.1619 30.6791 32.1619C35.7462 32.1619 39.5235 28.6609 39.5235 23.3174C39.5235 19.5401 37.6809 16.7763 34.6406 15.4865Z"
                fill="currentColor"
            />
        </svg>
    );
};

export const FacebookIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#facebookClip0)">
                <path
                    d="M2.76019 18.6143H7.11023V9.52225H10.1102L10.4302 6.48056H7.07019V4.74233C7.07019 4.02367 7.24019 3.73959 8.07019 3.73959H10.4202V0.614258H7.42023C4.19023 0.614258 2.73022 1.8008 2.73022 4.08214V6.46383H0.470215V9.54734H2.73022V18.6059L2.76019 18.6143Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="facebookClip0">
                    <rect width="9.96" height="18" fill="white" transform="translate(0.5 0.581055)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const InstagramIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#instagramClip0)">
                <path
                    d="M9.55666 5.07608C10.1651 5.07434 10.7678 5.19418 11.3292 5.42867C11.8906 5.66315 12.3994 6.00743 12.8258 6.4414H18.5268V4.11907C18.5268 3.18068 18.1541 2.28094 17.4905 1.6174C16.827 0.953853 15.927 0.581055 14.9886 0.581055H5.31092V5.04615H4.58325V0.581055H4.08491V5.04615H3.35735V0.6706C3.18413 0.710833 3.0142 0.764298 2.84904 0.830224V5.03617H2.12148V1.21882C1.65919 1.54581 1.28215 1.97899 1.022 2.48194C0.761849 2.9849 0.62619 3.54284 0.626465 4.10909V6.43143H6.3076C6.73249 6.00165 7.23841 5.66062 7.79617 5.42794C8.35393 5.19525 8.95231 5.07568 9.55666 5.07608ZM13.8524 2.62405C13.8524 2.47338 13.9123 2.3291 14.0188 2.22256C14.1254 2.11601 14.2698 2.05612 14.4205 2.05612H16.3341C16.4848 2.05612 16.6293 2.11601 16.7359 2.22256C16.8424 2.3291 16.9023 2.47338 16.9023 2.62405V4.6174C16.9023 4.76808 16.8424 4.9126 16.7359 5.01914C16.6293 5.12568 16.4848 5.18558 16.3341 5.18558H14.4205C14.2698 5.18558 14.1254 5.12568 14.0188 5.01914C13.9123 4.9126 13.8524 4.76808 13.8524 4.6174V2.62405Z"
                    fill="currentColor"
                />
                <path
                    d="M11.8793 6.95998C11.6189 6.73281 11.3268 6.54474 11.0122 6.40178C10.5552 6.19417 10.0589 6.08691 9.55698 6.08691C9.05501 6.08691 8.55889 6.19417 8.10187 6.40178C7.78726 6.54474 7.49516 6.73281 7.23476 6.95998C6.69424 7.43254 6.31077 8.05878 6.13552 8.75502C5.96027 9.45126 6.00164 10.1843 6.25402 10.8564C6.50641 11.5286 6.95785 12.1078 7.54805 12.5167C8.13825 12.9255 8.83902 13.1444 9.55698 13.1444C10.2749 13.1444 10.9758 12.9255 11.566 12.5167C12.1562 12.1078 12.6077 11.5286 12.8601 10.8564C13.1124 10.1843 13.1538 9.45126 12.9786 8.75502C12.8033 8.05878 12.4198 7.43254 11.8793 6.95998ZM9.56695 12.2225C9.05049 12.2225 8.54565 12.0692 8.11622 11.7823C7.6868 11.4954 7.35211 11.0876 7.15446 10.6104C6.95682 10.1333 6.90515 9.60831 7.00591 9.10177C7.10667 8.59522 7.35537 8.12987 7.72057 7.76467C8.08576 7.39947 8.551 7.15077 9.05754 7.05001C9.56408 6.94925 10.0892 7.00104 10.5663 7.19869C11.0435 7.39633 11.4512 7.7309 11.7382 8.16032C12.0251 8.58975 12.1782 9.09459 12.1782 9.61106C12.1848 9.95399 12.1238 10.295 11.9987 10.6143C11.8735 10.9337 11.6867 11.2252 11.4489 11.4723C11.211 11.7194 10.9268 11.9173 10.6124 12.0546C10.2981 12.1918 9.95977 12.2659 9.61683 12.2723L9.56695 12.2225Z"
                    fill="currentColor"
                />
                <path
                    d="M4.15516 18.5811H14.9791C15.9175 18.5811 16.8174 18.2083 17.481 17.5448C18.1445 16.8813 18.5173 15.9815 18.5173 15.0431V6.95996H13.2848C13.7738 7.64555 14.0644 8.45258 14.125 9.29252C14.1855 10.1325 14.0136 10.9728 13.628 11.7214C13.2424 12.4701 12.6581 13.0982 11.9392 13.5367C11.2202 13.9751 10.3943 14.207 9.55221 14.207C8.71009 14.207 7.88413 13.9751 7.16515 13.5367C6.44617 13.0982 5.86191 12.4701 5.47632 11.7214C5.09073 10.9728 4.91876 10.1325 4.97932 9.29252C5.03988 8.45258 5.33053 7.64555 5.81954 6.95996H0.646837V14.9932C0.640252 15.4596 0.725934 15.9226 0.898926 16.3556C1.07192 16.7887 1.32879 17.1834 1.65483 17.5168C1.98087 17.8503 2.36963 18.116 2.79872 18.2986C3.22782 18.4813 3.68882 18.5772 4.15516 18.5811Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="instagramClip0">
                    <rect width="17.9103" height="18" fill="white" transform="translate(0.616699 0.581055)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const TwitterIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#twitterClip0)">
                <path
                    d="M0.459961 16.5434C2.45558 17.8232 4.76476 18.5296 7.13483 18.5849C9.5049 18.6402 11.8446 18.0425 13.8977 16.8572C15.9509 15.672 17.6385 13.9447 18.7758 11.8646C19.9131 9.78454 20.4564 7.43178 20.3461 5.06363C21.2347 4.42788 22.0034 3.63946 22.6165 2.73504C21.7874 3.10055 20.9083 3.33978 20.0084 3.4451C20.9596 2.87824 21.6715 1.98426 22.011 0.930323C21.1187 1.46116 20.1421 1.8352 19.1236 2.03635C18.437 1.31186 17.5315 0.833044 16.5463 0.673644C15.561 0.514244 14.5506 0.683247 13.6706 1.15431C12.7907 1.62538 12.0899 2.37247 11.6762 3.2808C11.2626 4.18913 11.1588 5.20826 11.3811 6.1813C9.58294 6.08346 7.82522 5.6103 6.22103 4.79217C4.61684 3.97403 3.20171 2.82894 2.06669 1.43089C1.48697 2.43177 1.30994 3.61576 1.5716 4.74242C1.83326 5.86908 2.51403 6.85417 3.47552 7.49711C2.75289 7.47415 2.04624 7.27854 1.41469 6.92661C1.40154 7.98601 1.75829 9.01672 2.4235 9.84133C3.08871 10.6659 4.02074 11.2327 5.05894 11.4439C4.39098 11.6266 3.69013 11.6546 3.00977 11.5255C3.30087 12.4288 3.86698 13.2185 4.62894 13.7842C5.39089 14.3499 6.31062 14.6634 7.25947 14.6807C6.30589 15.4259 5.2138 15.9743 4.04658 16.2941C2.87935 16.6139 1.66021 16.6985 0.459961 16.5434Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="twitterClip0">
                    <rect width="22.1565" height="18" fill="white" transform="translate(0.459961 0.581055)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const BurgerIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.4502 33.3125C7.0918 33.3125 5.1875 31.4082 5.1875 29.0498C5.1875 28.7715 5.2168 28.5078 5.27539 28.2295C6.05176 25.1533 12.043 5.1875 38 5.1875C63.957 5.1875 69.9482 25.1533 70.7246 28.2295C70.7979 28.5078 70.8125 28.7715 70.8125 29.0498C70.8125 31.4082 68.9082 33.3125 66.5498 33.3125H9.4502ZM21.5938 19.25C21.5938 18.6284 21.3468 18.0323 20.9073 17.5927C20.4677 17.1532 19.8716 16.9062 19.25 16.9062C18.6284 16.9062 18.0323 17.1532 17.5927 17.5927C17.1532 18.0323 16.9062 18.6284 16.9062 19.25C16.9062 19.8716 17.1532 20.4677 17.5927 20.9073C18.0323 21.3468 18.6284 21.5938 19.25 21.5938C19.8716 21.5938 20.4677 21.3468 20.9073 20.9073C21.3468 20.4677 21.5938 19.8716 21.5938 19.25ZM56.75 21.5938C57.3716 21.5938 57.9677 21.3468 58.4073 20.9073C58.8468 20.4677 59.0938 19.8716 59.0938 19.25C59.0938 18.6284 58.8468 18.0323 58.4073 17.5927C57.9677 17.1532 57.3716 16.9062 56.75 16.9062C56.1284 16.9062 55.5323 17.1532 55.0927 17.5927C54.6532 18.0323 54.4062 18.6284 54.4062 19.25C54.4062 19.8716 54.6532 20.4677 55.0927 20.9073C55.5323 21.3468 56.1284 21.5938 56.75 21.5938ZM40.3438 14.5625C40.3438 13.9409 40.0968 13.3448 39.6573 12.9052C39.2177 12.4657 38.6216 12.2188 38 12.2188C37.3784 12.2188 36.7823 12.4657 36.3427 12.9052C35.9032 13.3448 35.6562 13.9409 35.6562 14.5625C35.6562 15.1841 35.9032 15.7802 36.3427 16.2198C36.7823 16.6593 37.3784 16.9062 38 16.9062C38.6216 16.9062 39.2177 16.6593 39.6573 16.2198C40.0968 15.7802 40.3438 15.1841 40.3438 14.5625ZM2.84375 45.0312C2.84375 41.1494 5.99316 38 9.875 38H66.125C70.0068 38 73.1562 41.1494 73.1562 45.0312C73.1562 48.9131 70.0068 52.0625 66.125 52.0625H9.875C5.99316 52.0625 2.84375 48.9131 2.84375 45.0312ZM5.1875 59.0938C5.1875 57.8047 6.24219 56.75 7.53125 56.75H68.4688C69.7578 56.75 70.8125 57.8047 70.8125 59.0938V61.4375C70.8125 66.6084 66.6084 70.8125 61.4375 70.8125H14.5625C9.3916 70.8125 5.1875 66.6084 5.1875 61.4375V59.0938Z"
                fill="currentColor"
            />
        </svg>
    );
};

export const GiftSolidIcon = (props: SVGProps<SVGSVGElement>) => {
    const id = useId();
    return (
        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath={`url(#${id})`}>
                <path
                    d="M28.4053 10.5781L33.5029 19.25H33.3125H22.7656C19.5283 19.25 16.9062 16.6279 16.9062 13.3906C16.9062 10.1533 19.5283 7.53125 22.7656 7.53125H23.0879C25.2705 7.53125 27.3066 8.68848 28.4053 10.5781ZM9.875 13.3906C9.875 15.5 10.3877 17.4922 11.2812 19.25H5.1875C2.59473 19.25 0.5 21.3447 0.5 23.9375V33.3125C0.5 35.9053 2.59473 38 5.1875 38H70.8125C73.4053 38 75.5 35.9053 75.5 33.3125V23.9375C75.5 21.3447 73.4053 19.25 70.8125 19.25H64.7188C65.6123 17.4922 66.125 15.5 66.125 13.3906C66.125 6.27148 60.3535 0.5 53.2344 0.5H52.9121C48.2393 0.5 43.9033 2.97559 41.5303 7.00391L38 13.0244L34.4697 7.01855C32.0967 2.97559 27.7607 0.5 23.0879 0.5H22.7656C15.6465 0.5 9.875 6.27148 9.875 13.3906ZM59.0938 13.3906C59.0938 16.6279 56.4717 19.25 53.2344 19.25H42.6875H42.4971L47.5947 10.5781C48.708 8.68848 50.7295 7.53125 52.9121 7.53125H53.2344C56.4717 7.53125 59.0938 10.1533 59.0938 13.3906ZM5.1875 42.6875V68.4688C5.1875 72.3506 8.33691 75.5 12.2188 75.5H33.3125V42.6875H5.1875ZM42.6875 75.5H63.7812C67.6631 75.5 70.8125 72.3506 70.8125 68.4688V42.6875H42.6875V75.5Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id={id}>
                    <rect width="75" height="75" fill="white" transform="translate(0.5 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
